// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-dynamist-experience-index-js": () => import("./../../../src/pages/dynamist-experience/index.js" /* webpackChunkName: "component---src-pages-dynamist-experience-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-index-js": () => import("./../../../src/pages/join-us/index.js" /* webpackChunkName: "component---src-pages-join-us-index-js" */),
  "component---src-pages-our-superpowers-index-js": () => import("./../../../src/pages/our-superpowers/index.js" /* webpackChunkName: "component---src-pages-our-superpowers-index-js" */),
  "component---src-pages-qr-js": () => import("./../../../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-services-jsx": () => import("./../../../src/pages/services/Services.jsx" /* webpackChunkName: "component---src-pages-services-services-jsx" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-templates-industry-index-js": () => import("./../../../src/templates/industry/index.js" /* webpackChunkName: "component---src-templates-industry-index-js" */),
  "component---src-templates-join-us-index-js": () => import("./../../../src/templates/joinUs/index.js" /* webpackChunkName: "component---src-templates-join-us-index-js" */),
  "component---src-templates-solutions-index-js": () => import("./../../../src/templates/solutions/index.js" /* webpackChunkName: "component---src-templates-solutions-index-js" */)
}

